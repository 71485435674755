<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end mobile-view">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
                                placeholder="Search..."
                                @input="filterTable"
                            />
                            <b-button variant="warning" :to="{name:'add-misconduct'}" class="mr-1 mobile-margin-bottom">
                                <span class="text-nowrap">Add</span>
                            </b-button>

                            <b-button variant="danger" class="mr-1 mobile-margin-bottom" @click="changeMultipleStatus('archive')">
                                <span class="text-nowrap">Delete</span>
                            </b-button>

                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative sTable"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
                :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
            >

                <template #head(checkbox)="items">
                    <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall"/>
                </template>

                <template #cell(checkbox)="items">
                    <b-form-checkbox v-model="selected" @change="selectAll = false" :value="items.item._id" class="custom-control-warning" />
                </template>

                <template #cell(order)="items">
                    <b-form-input placeholder="" autocomplete="off" v-model="items.item.order" @change="updateOrder(items.item)" @keypress="isNumber($event)"/>
                </template>

                <template #cell(misconduct_en)="items">
                    <span class="wordBreak">{{ items.item.misconduct_en | capitalize}}</span>
                </template>   

                <template #cell(misconduct_ch)="items">
                    <span class="wordBreak">{{ items.item.misconduct_ch }}</span>
                </template>   
                
                <template #cell(status)="items">
                    <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize">
                        {{ items.item.status}}
                    </b-badge>
                </template>

                <template #cell(created_at)="items">
                    <span>{{ items.item.created_at | dateTime}}</span>
                </template>

                <template #cell(actions)="items">
                    <!-- <b-dropdown
                        
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    > -->
                        <!-- 3 dot menu -->
                        <!-- <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template> -->

                        <!-- Edit button -->
                        <!-- <b-dropdown-item :to="{ name: 'edit-misconduct', params: { id: items.item._id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item> -->

                        <!-- Change Status button -->
                        <!-- <b-dropdown-item @click="statusChange(items.item)">
                            <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
                            <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
                            <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
                        </b-dropdown-item> -->

                        <!-- Delete button -->
                        <!-- <b-dropdown-item @click="deleteItem(items.item._id)">
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item> -->

                    <!-- </b-dropdown>  -->

                     <b-link  :to="{ name: 'edit-misconduct', params: { id: items.item._id } }"
                        v-b-tooltip.hover.v-warning
                        title="Edit"
                        variant="outline-warning"
                    >
                        <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
                        
                    </b-link>

                      <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                        <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
                    </b-link>

                     <b-link  @click="deleteItem(items.item._id)"
                        v-b-tooltip.hover.v-warning
                        title="Delete"
                        variant="outline-warning"
                    >
                        <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
                        
                    </b-link>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>

        <b-modal 
            id="pass"
            ref="pass"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Verify Password"
            @ok="verifyPassword"
            no-close-on-backdrop
        >
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Password" class="required">
                            <b-form-input placeholder="" v-model="password"/>
                        </b-form-group>
                    </b-col>
                </b-row>      
            </b-form>

        </b-modal>
    
    </div>
</template>

<script>
    import {
        BCardBody, VBTooltip, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BBreadcrumb
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { POST_API } from "../../../store/actions.type"

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BBreadcrumb
        },
         directives: {
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                tableColumns: [
                    { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%'}},
                    { key: 'order', label: 'S.No', sortable: false , thStyle:  {width: '12%'}},
                    { key: 'misconduct_en', label: 'Misconduct (en)', sortable: false , thStyle:  {width: '20%'}},
                    { key: 'misconduct_ch', label: 'Misconduct (ch)', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '10%'}},
                    { key: 'created_at', label: 'Added On', sortable: false , thStyle:  {width: '15%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '22%'}},
                ], 

                // b-table and pagination options
                items           : [],
                isSortDirDesc   : false,
                perPageOptions  : [10, 20, 50, 100],
                perPage         : 10,
                sortBy          : 'order',
                totalRecords    : 0,
                currentPage     : 1,
                searchQuery     : '',
                from            : null,
                to              : null, 
                
                // multicheckbox vars
                selectAll : false,
                selected  : [],
                delType   : '',
                delStatus : '',
                password  : '',
            }
        },

        methods : {
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                    },
                    api: '/api/misconduct-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            statusChange(item){
                if (item.status == 'active') {
                    var msg     = 'Are you sure want to inactivate this record?';
                } else{
                    var msg     = 'Are you sure want to activate this record?';
                }

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : item._id,
                                status : item.status
                            },
                            api : "/api/change-misconduct-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.successAlert();
                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            deleteItem(id){
                var msg = 'Are you sure want to delete this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-misconduct-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            // multicheckbox functions
            selectall(e){
                this.selected = [];
                if (e) {
                    var selected = [];

                    this.items.forEach(function(item){
                        selected.push(item._id);
                    })

                    this.selected = selected;
                }
            },

            changeMultipleStatus(status){
                this.password = '';

                this.delType = 'multiple';
                this.delStatus = status;

                if (this.selected.length > 0) {
                    var msg = '';
                    var msg2 = '';

                    if (status == 'archive') {
                        msg = 'Are you sure you want to delete selected record?';
                        msg2 = 'Records Deleted Successfully.';
                    }

                    Swal.fire({
                        title: 'Please Confirm',
                        text: msg,
                        icon: 'warning',
                        position: 'top-center',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    })
                    .then(result => {
                        if (result.value) {
                            this.$refs['pass'].show();
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })   
                }else{
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please select Misconduct(s).',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },

            verifyPassword(e){
                e.preventDefault();

                return this.$store.dispatch(POST_API, {
                    data:{
                        user_id: this.$store.getters.currentUser._id,
                        password:this.password
                    },
                    api:"/api/verify-password",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.deleteMultiQuestion();
                        this.$refs['pass'].hide();
                    }
                });
            },

            deleteMultiQuestion(){
                var message='Misconduct Deleted Successfully.';
                
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : this.selected,
                        status :'deleted'
                        },
                    api:"/api/change-misconduct-status-multi",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.successAlert();
                        this.$refs.refUserListTable.refresh();
                        this.selectAll = false;
                    }
                });
            },

            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'warning-letter'},
                text:'Warning Letter',
              },{
                to:null,
                text:'Misconduct',
                active:true
              }];
              return item;
            },

            updateOrder(item){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : item._id,
                        order  : item.order
                    },
                    api:"/api/misconduct-update-order",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.$refs.refUserListTable.refresh();
                    }
                });

            },

            isNumber($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                // only allow number 
                if (keyCode < 48 || keyCode > 57) {
                    $event.preventDefault();
                }
            },

        },
        mounted(){
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
